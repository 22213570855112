import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const FullScreenOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: #2b3646;

  opacity: 0.98;
  color: #efefef;

  .row-overlay {
    padding: 3rem 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .navlink-overlay {
    padding: 1.2rem 0;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-family: azo-sans-web, sans-serif;
    font-size: 1.3rem;
    font-weight: 900;
    color: #f3f3f3;
    text-decoration: none;
  }

  a.navlink-overlay:hover {
    color: #ffbd00;
  }

  .row-overlay .active {
    color: #ffbd00;
    border: none;
  }
`

function MenuOverlay() {
  return (
    <FullScreenOverlay>
      <nav className="row-overlay">
        <Link to="/" activeClassName="active" className="navlink-overlay">
          Home
        </Link>
        <Link to="about" activeClassName="active" className="navlink-overlay">
          About
        </Link>

        <Link to="contact" activeClassName="active" className="navlink-overlay">
          Contact Us
        </Link>
        <Link to="build" activeClassName="active" className="navlink-overlay">
          Build
        </Link>
      </nav>
    </FullScreenOverlay>
  )
}

export default MenuOverlay
