import styled from "styled-components"

export const PrimaryTitle = styled.h2`
  text-transform: uppercase;
  text-align: center;
  font-size: 2.2rem;
  color: #2b3646;
  margin: 3rem 0 2.5rem 0;
  line-height: 3rem;
`

export const SecondaryTitle = styled(PrimaryTitle)`
  font-size: 2.2rem;
  color: #f2f2f2;
`
