import styled from "styled-components"

export const PrimaryButton = styled.button`
  font-family: azo-sans-web, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  padding: 0;
  margin: 0;
  background: #ffbd00;
  color: #2b3646;
  font-size: 1rem;
  width: 180px;
  height: 55px;
  margin: 2rem 0;
  cursor: pointer;
  border: none;
  transition: all 0.3s;

  :hover {
    background: #2b3646;
    color: #efefef;
    border: solid 1px #efefef;
    transition: all 0.5s;
  }
`
