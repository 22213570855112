import React, { useState } from "react"
import styled from "styled-components"
import MenuOverlay from "./MenuOverlay"

const BurgerDiv = styled.div`
  z-index: 99;

  @media (min-width: 716px) {
    display: none;
  }

  button {
    width: 50px;
    height: 40px;
    border: none;
    cursor: pointer;
    background: none;
  }

  button:focus {
    outline: 0;
  }

  .open {
    span:nth-child(1) {
      top: 8px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
      background: #ffffff;
    }
    span:nth-child(2) {
      opacity: 0;
      left: -20px;
    }

    span:nth-child(3) {
      top: 8px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
      background: #ffffff;
    }
  }

  width: 35px;
  height: 5px;
  position: absolute;
  top: 45px;
  right: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #2b3646;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2) {
    top: 8px;
  }

  span:nth-child(3) {
    top: 16px;
  }
`

const XOverflow = styled.div`
  position: absolute;
  ${props => (props.visible ? "top: 0%" : "top: -150%")};
  transition: all 400ms;
  z-index: 98;
`

function BurgerMenu() {
  const [toggle, setToggle] = useState(false)

  const handleClick = () => {
    setToggle(!toggle)
  }

  return (
    <>
      <XOverflow visible={toggle}>
        <MenuOverlay />
      </XOverflow>
      <BurgerDiv>
        <button onClick={handleClick} className={toggle ? "open" : ""}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </BurgerDiv>
    </>
  )
}

export default BurgerMenu
