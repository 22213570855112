import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import logo from "../images/Alto-Logo.svg"
import BurgerMenu from "./ui/BurgerMenu"

const StyledHeader = styled.header`
  border-bottom: 1px solid #2a3546;

  .row {
    padding: 1rem 0;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .brand img {
    width: 200px;
    margin: 0 3.2rem;
  }

  .navlink {
    padding: 1rem 0;
    margin: 0 2.5rem;
    text-align: center;
    text-transform: uppercase;
    font-family: azo-sans-web, sans-serif;
    font-size: 1.2rem;
    font-weight: 900;
    color: #596979;
    text-decoration: none;
  }

  a.navlink:hover {
    color: #2b3646;
    border-bottom: 0.25rem solid #ffbd00;
  }

  .active {
    color: #2b3646;
    border-bottom: 0.3rem solid #ffbd00;
  }

  .navitem {
  }

  // Responsive css
  @media (max-width: 1060px) {
    .navlink {
      font-size: 0.9rem;
      margin: 0 2rem;
      transition: all 0.5s;
    }

    .brand img {
      width: 150px;
      margin: 0 1.4rem;
    }
  }

  @media (max-width: 780px) {
    .navlink {
      font-size: 0.8rem;
      margin: 0 1rem;
      transition: all 0.5s;
    }

    .brand img {
      width: 150px;
      margin: 0 1.4rem;
    }
  }

  @media (max-width: 715px) {
    .navlink {
      display: none;
    }

    .brand img {
      width: 140px;
      margin: 0 1.4rem;
    }
  }
`

const Header = () => (
  <StyledHeader>
    <BurgerMenu />

    <nav className="wrapper row">
      <div className="navitem">
        <Link to="/" activeClassName="active" className="navlink">
          Home
        </Link>
        <Link to="about" activeClassName="active" className="navlink">
          About
        </Link>
      </div>
      <div className="brand">
        <Link to="/" className="">
          <img src={logo} alt="Custom Alto Homes logo" />
        </Link>
      </div>
      <div className="navitem">
        <Link to="contact" activeClassName="active" className="navlink">
          Contact Us
        </Link>
        <Link to="build" activeClassName="active" className="navlink">
          Build
        </Link>
      </div>
    </nav>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
