import React from "react"
import styled from "styled-components"
import treesRight from "../images/trees-right-footer.png"
import treesLeft from "../images/trees-left-footer.png"
import footerLogo from "../images/FooterLogo.svg"

const StyledFooter = styled.footer`
  .footer-images {
    display: flex;
    justify-content: space-between;
    margin-bottom: -6px;
    overflow: hidden;
    height: 180px;

    background-image: url('${treesLeft}'),
    url('${treesRight}');

    background-size: 460px;

    background-repeat:   no-repeat, no-repeat;
                

    background-position: left 0 bottom -10px, right 0 bottom -10px;
    

  }

  .footer-images img {
    width: 400px;
  }

  .footer-content {
    padding: 2rem 0;
    border: none;

    img {
      width: 200px;
    }
    color: #f3f3f3;
    background-color: #2b3646;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .footer-content a {
    text-decoration: none;
    color: #ffbd00;
  }

  /* Footer copyright css */

  color: #8c9bac;
  text-align: center;

  a {
    text-decoration: none;
    color: #fefefe;
  }

  a:visited {
    color: #f3f3f3;
  }

  .footer-copyright {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    background-color: #2b3646;
    font-size: 0.7rem;
    border: none;
  }
`

function footer() {
  return (
    <StyledFooter>
      <div className="footer-images">
        {/* <img src={treesLeft} alt="silhouette of trees" />
        <img src={treesRight} alt="silhouette of trees" /> */}
      </div>
      <div className="footer-content">
        <img src={footerLogo} alt="Alto Homes Logo" />
        <div>
          <h3>
            <a
              href="tel:+15752013210"
              target="_blank"
              rel="noopener noreferrer"
            >
              575-201-3210
            </a>
          </h3>
          <h3>info@customaltohomes.com</h3>
        </div>
      </div>

      <div className="footer-copyright">
        © {new Date().getFullYear()}- Custom Alto Homes Designed by
        {` `}
        <a
          href="http://powerpunchinteractive.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Power Punch Interactive
        </a>
      </div>
    </StyledFooter>
  )
}

export default footer
